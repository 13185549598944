import BLiveClient from './BLiveClient'
import BrtcSessionManager from '../brtc/SessionManager'

export default class BLiveSessionManager extends BrtcSessionManager {
    constructor(log) {
        super(log)
    }

    _createSession() {
        return new BLiveClient(this.params)
    }

    _handleSessionEvent() {
        super._handleSessionEvent()
        this._session.on('mixStreamAdded', (data) => {
            this.emit('mixStreamAdded', data)
        })

        this._session.on('mixStreamRemoved', (data) => {
            this.emit('mixStreamRemoved', data)
        })
    }

    /**
     * 切换角色
     */
    switchRole(role) {
        if (this._session) {
            return this._session.switchRole(role)
        }
    }

    /**
     * 开启混流
     */
    startMixTranscode(data) {
        if (this._session) {
            return this._session.startMixTranscode(data)
        }
    }
    
    /**
     * 结束混流
     */
    stopMixTranscode(taskId) {
        if (this._session) {
            return this._session.stopMixTranscode(taskId)
        }
    }
}
