import BRTCClient from '../brtc/BRTCClient'
import * as CombineMode from '../brtc/CombineMode'

let role

export default class BLiveClient extends BRTCClient {
    constructor(options) {
        role = options.role
        super(options)
    }

    _createClient() {
        return global.BLive.createClient({
            codec: this.codec,
            mode: this.mode,
            appId: this.appId,
            comments: this.comments,
            role: role,
            publishOnly: !this.canSubscribe,
        })
    }

    handleEvents() {
        super.handleEvents()

        if (this.canSubscribe) {
            this.client
            .on('mix-stream-added', (data) => {
                CombineMode.addMixStream(this.streamType, data)
            })
            .on('mix-stream-removed', (data) => {
                CombineMode.removeMixStream(this.streamType, data)
            })
        }
    }

    switchRole(role) {
        return this.client.switchRole(role)
    }

    /**
     * 开启混流
     */
    startMixTranscode(data) {
        return this.client.startMixTranscode(data)
    }

    /**
     * 结束混流
     */
    stopMixTranscode(taskId) {
        return this.client.stopMixTranscode(taskId)
    }
}
